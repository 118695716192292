import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LatestChart from "./charts/LatestChart";
import axios from 'axios';
import SessionsTable from "./Table/SessionsTable";
import {Device, Session} from "../interfaces/Datapoints";

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const [selectedDevice, setSelectedDevice] = useState<string>('');
    const [sessions, setSessions] = useState<Session[]>([]);
    const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
    const [devices, setDevices] = useState<Device[]>([]);
    useEffect(() => {
        // Fetch devices on component mount
        //axios.get('http://localhost:5011/device/all')
        axios.get('http://ethersense.tech/api/device/all')
            .then(response => {
                setDevices(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the devices:', error);
            });
    }, []);

    // Get all the datapoints for the latest session (maybe not streaming? )
    // Get all the sessions and their assosiated times + maybe number of datapoints
    useEffect(() => {
        // Fetch sessions whenever selectedDevice changes, but not on initial render (when selectedDevice is '')
        if (selectedDevice) {

            // axios.get('http://localhost:5011/sessions/device', {
            axios.get('http://ethersense.tech/api/sessions/device', {
                params: {
                    device_id: selectedDevice || "84:FC:E6:7B:73:88"
                }
            })
                .then(response => {
                    console.log(response.data);
                    response.data.forEach((elm:any, index:number)=>{
                        if (elm.session_name === 'Unnamed'){
                            response.data[index].session_name = `Session ${index + 1}`;
                            response.data[index].created_at = new Date(elm.created_at);
                        }
                    })
                    setSessions(response.data);

                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [selectedDevice]);

    const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDevice(event.target.value);
    };

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/login');
    };


    function handleClick() {
        navigate('/livestream');
    }

    return (
        <div className="body">
            <h1>Latest Session</h1>
                <div className="chart-bar button-container">
                    <button type="button" onClick={handleClick}>
                        Go to Live
                    </button>
                </div>
                <div className="chart">
                    <LatestChart/>
                </div>
            <div>
                <select className="device-select" value={selectedDevice} onChange={handleDeviceChange}>
                    <option value="">Select a Device</option>
                    {Array.isArray(devices) && devices.map(device => (
                        <option key={device.device_id} value={device.device_id}>
                            {device.device_name_unique}
                        </option>
                    ))}
                </select>
            </div>
            <SessionsTable sessions={sessions}
                          selectedSessionId={selectedSessionId}
                          onSessionSelect={setSelectedSessionId}
            />
        </div>
    );
};

export default Dashboard;