import React from 'react';
import StreamingGraph from "./charts/StreamingGraph";

const YourComponent = () => {

    return (
        <div className="body">
            <h1> Stream Data from Device </h1>
            <StreamingGraph/>
        </div>
    )
};

export default YourComponent;