import React, { useEffect, useState } from 'react';
import {VictoryScatter, VictoryChart, VictoryAxis, VictoryTooltip, VictoryTheme} from 'victory';

type DataType = { time: Date; value: number };

let first : Date = new Date();
let last : Date = new Date(first.getTime() + 0.55 * 60 * 1000);
let isFirst:boolean = false
const StreamingGraph: React.FC = () => {
    const [data, setData] = useState<DataType[]>([]);

    function isTimeDifferenceGreaterThanFiveMinutes(date1:Date, date2:Date) {
        const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
        const differenceInMinutes = differenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
        let isBad =  differenceInMinutes > 0.5;
        return isBad;
    }
    function isDate(obj: any): obj is Date {
        return obj instanceof Date;
    }

    useEffect(() => {
        //const eventSource = new EventSource('http://localhost:5000/stream/123');
        const eventSource = new EventSource('http://ethersense.tech/stream/30:30:F9:26:E2:38'); //30:30:F9:26:E1:14');

        eventSource.onmessage = (event) => {
            const newData = JSON.parse(event.data);
            if (isDate(new Date(newData.time / 1000))){
                newData.time = new Date(newData.time / 1000)
            }
            // @ts-ignore
            setData((currentData) => {
                console.log(data, currentData)
                if (currentData && currentData.length === 1 && !isFirst ){
                    // @ts-ignore
                    first = new Date(currentData[0].time);
                    // @ts-ignore
                    last = new Date(first.getTime() + 0.55 * 60 * 1000);//new Date(currentData.time);
                    isFirst = true;
                }
                if (currentData){
                    if (currentData.length > 1 && isTimeDifferenceGreaterThanFiveMinutes(first, new Date(currentData[currentData.length-1].time))){
                        first = new Date(currentData[currentData.length - 1].time);
                        last = new Date(first.getTime() + 0.55 * 60 * 1000);
                        console.log("Resetting!")
                        return []
                    }
                    else {
                        // Append an array of objects instead
                        return [...currentData, { time: newData.time, value: newData.value}]
                    }
                }

            });
        };
        eventSource.onerror = (error) => {
            console.log(error)
        }

        return () => {
            eventSource.close();
        };
    }, []);

    return (
        <VictoryChart
            theme={VictoryTheme.material}
            width={800}
            height={400}
            // style={{
            // parent: {
            //     height: '43vh',
            //     width: '95vw'
            // }}}
        >
            <VictoryAxis
                tickFormat={(t) => `${new Date(t).getHours()}:${('0' + new Date(t).getMinutes()).slice(-2)}:${new Date(t).getSeconds()}`}
                label="Time"
                style={{
                    axisLabel: {padding: 40 , fontSize: 5 },
                    tickLabels: {
                        fontSize: 5,
                        angle: 45, // Rotate the labels
                        verticalAnchor: 'middle',
                        textAnchor: 'start'
                    },
                    // parent: {
                    //     height: '34vh',
                    //     width: '95vw'
                    // },
                    grid: { stroke: "none" }
                }}
                tickCount={4}
                domain={{
                    //@ts-ignore
                    x: [first, last]
                }}
            />
            <VictoryAxis
                dependentAxis
                label="Current"
                style={{
                    tickLabels: { fontSize: 5 },
                    axisLabel: {padding: 40 , fontSize: 5},
                    grid: { stroke: "none" }
                }}
                domain={{
                    //@ts-ignore
                    y: [0,2]
                }}
            />
            <VictoryScatter
                data={data.map((d) => ({ x: new Date(d.time), y: d.value }))}
                // size={1}
                style={{
                    data: {
                        fill: "#563d7c",
                        cursor: "pointer",
                    }
                }}
                labels={({ datum }) => `Time: ${datum.x.getHours()}:${datum.x.getMinutes()}:${datum.x.getSeconds()}\nCurrent: ${datum.y.toFixed(2)}`} // Customize as needed
                labelComponent={
                    <VictoryTooltip
                        //pointerWidth={20}
                        flyoutStyle={{ stroke: "#000000", strokeWidth: 1, fill: "white" }}
                        style={{ fill: "#000000", textAnchor: "middle", fontSize: 5}} // Style for the tooltip text
                        centerOffset={{ x: -15 }}
                    />
                }
            />
        </VictoryChart>
    );
};

export default StreamingGraph;
