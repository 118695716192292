import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Session, TimeSeriesData} from '../../interfaces/Datapoints'

interface SessionsTableProps {
    sessions: Session[];
    selectedSessionId: string | null;
    onSessionSelect: (sessionId: string) => void;
}
const SessionsTable: React.FC <SessionsTableProps> = ({ sessions, selectedSessionId, onSessionSelect }) => {
    const [dataPoints, setDataPoints] = useState<TimeSeriesData[]>([]);
    const handleSessionSelect = async (session_id: string) => {
        onSessionSelect(session_id); // Call the prop function passed from the parent component.
    };

    useEffect(() => {
        const fetchSessionData = async () => {
            if (!selectedSessionId) return; // If no session is selected, do nothing.
            try {
                // const response = await axios.get('http://127.0.0.1:5011/sessions/datapoints', {
                const response = await axios.get('http://ethersense.tech/api/sessions/datapoints', {
                    params: {
                        session_id: selectedSessionId
                    }
                });
                const datapoints = response.data.map((d: number[]) => ({
                    current_0: d[0],
                    current_1: d[1],
                    timestamp: d[2],
                }));
                setDataPoints(datapoints);
                localStorage.setItem(selectedSessionId, JSON.stringify(datapoints));
            } catch (error) {
                console.error('Error fetching data points:', error);
            }
        };


        fetchSessionData();
    }, [selectedSessionId]);

    return (
        <div>
            {/*<h2>Select a session to view data points:</h2>*/}
            <table className="clickable-table">
                <thead>
                <tr>
                    <th>Session Name</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                {sessions.map((session:Session) => {
                    return (
                        <tr key={session.session_id} onClick={() => handleSessionSelect(session.session_id)}>
                            <td>{session.session_name}</td>
                            <td>{session.created_at}</td>
                            {/*would be nice to add the number of datapoints too */}
                            {/*or duration*/}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default SessionsTable;
