import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5011/login', { username, password });
            //const response = await axios.post('http://ethersense.tech/api/login', { username, password });
            localStorage.setItem('username', username);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user_id);
            navigate('/dashboard');
        } catch (error : any) {
            if (error.response && error.response.status !== 401) {
                console.error('An error occurred:', error.message);
            } else {
                console.error('Invalid username or password');
            }
        }
    };

    return (
        <div className="body">
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;