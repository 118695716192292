// TopBar.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';


const TopBar: React.FC = () => {
    const navigate = useNavigate();
    function handleClick() {
        navigate('/livestream');
    }
    return (

        <div className="topbar">
            <button className="logout-button">Logout</button>
            <div className="chart-bar button-container">
                <button type="button" onClick={handleClick}>
                    Go to Live
                </button>
            </div>
        </div>
)
    ;
}

export default TopBar;