import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import LoginForm from "./components/Login/LoginForm";
import Streaming from "./components/Streaming";
import Topbar from "./components/Topbar/Topbar";

function App() {
  return (
      <Router>
          <Topbar/>
          <div className="app-body">
              <Routes>
                  <Route path="/login" element={<LoginForm/>}/>
                  <Route path="/dashboard" element={<Dashboard/>}/>
                  <Route path="/" element={<LoginForm/>}/>
                  <Route path="/livestream" element={<Streaming/>}/>
              </Routes>
          </div>
      </Router>
  );
}

export default App;
